.sideBar-main{
    background: linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25));
    border-radius: 0.75rem;
    width: 16.5%;
    box-shadow: 0rem 1.25rem 1.6875rem 0rem #f0f2f5;
}
.sidebar-accountName{
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 96%;
    background: #5c5c60;
    padding: 10px;
    border-radius: 5px;
    font-weight: lighter;
    font-family: "Roboto, Helvetica, Arial, sans-serif";
}

.sidebar-logo-main{
    cursor:pointer;
    width:10vw;
    height:auto;
    align-items:flex-start
}

.sidebar-divider{
    opacity: 0.5;
    margin: 1rem 0px 10px;
    width: 96%;
    height: 0.06rem;
    border-top: 0px solid rgba(0, 0, 0, 0.08) !important;
    border-right: 0px solid rgba(0, 0, 0, 0.08) !important;
    border-left: 0px solid rgba(0, 0, 0, 0.08) !important;
    border-bottom: none !important;
    background-color: transparent;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgb(255, 255, 255), rgba(255, 255, 255, 0)) !important;
}

.sidebar-headings{
    font-size: 1rem;
     font-weight: 400
}


@media only screen and (max-width: 1500px) {
    .sidebar-logo-main {
      height: 40px;
      width: 11vw;
    }
    .sidebar-headings{
        /* font-size: 0.1rem;  Start by changing this */
    }

  }