.feed-info-grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr; 
    width: 100%; 
}
.feed-info-cards{
    border-radius: 6px;
    margin: 20px 5px;
}
.feed-info-card-blue{
    background: radial-gradient(circle at 1.8% 4.8%, rgb(17, 23, 58) 0%, rgb(58, 85, 148) 90%);

}

.feed-info-card-pink{
    background: linear-gradient(90deg, rgba(0,0,0,1) 20%, rgba(145,91,142,1) 100%);

}
.feed-info-card-yellow{
    background: linear-gradient(90deg, rgba(0,0,0,1) 20%, rgba(252,176,69,1) 100%);

}
.feed-info-card-leafGreen{

background: linear-gradient(90deg, rgba(0,0,0,1) 20%, rgba(95,145,91,1) 100%);
}

.feed-info-card-brown{

    background: linear-gradient(90deg, rgba(0,0,0,1) 20%, rgba(145,116,91,1) 100%);
}

.feed-info-card-green{
    background: radial-gradient(circle at 10% 20%, rgb(0, 93, 133) 0%, rgb(0, 181, 149) 90%);
}
.feed-info-card-reddark{

background: linear-gradient(90deg, rgba(0,0,0,1) 20%, rgba(170,0,0,1) 100%);
}

.feed-info-card-blue2{
    background: linear-gradient(90deg, rgba(0,0,0,1) 20%, rgba(0,12,170,1) 100%);

}

.feed-info-card-red{
    background: linear-gradient(74.3deg, rgb(68, 17, 28) 17.4%, rgb(95, 24, 40) 34.7%, rgb(122, 31, 51) 51.8%, rgb(149, 38, 62) 67.4%, rgb(163, 41, 68) 89.6%);

}

.feed-info-card-grey{
    background: linear-gradient(60deg, #29323c 0%, #485563 100%);

}
.feed-graph-desc{
    display: flex;
  align-items: flex-start;
  color: #202022;
  font-family: 'Poppins, sans-serif';
  font-weight: 300;
  font-size: 15px;
  text-align: start;
}
.chart-india{
    position: relative;
    top: -35px;
    right:55%;
    height: 500px;
    display: inline-block;
    cursor:default;
}
.chart-india > svg {
  display: inline-block;
  height: 115%;
}
.chart-india >.HoverInfo{
   top: 21% !important;
   color:black !important;
   font-size:large
}
/* responsive, form small screens, use 13px font size */


@media only screen and (max-width: 1500px) {
    .feed-info-grid{
        font-size: small;
    }
    .feed-graph-desc{
        font-size: 10px;
    }

  }