@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

body{
background-color: #f0f2f5;
font-family: 'Poppins', sans-serif;
color: white;
}
.sign-in-form{
    height: 70vh;
    padding-top: 10vh;
}
.highlighted-row {
    background-color: yellow; /* Change this to the desired background color */
  }
  .highlighted-row-booked {
    background: linear-gradient(109.6deg, rgb(255, 230, 109) 11.2%, rgb(87, 232, 107) 100.2%);
}
  .highlighted-row-sold {
    background: linear-gradient(107.2deg, rgb(150, 15, 15) 10.6%, rgb(247, 0, 0) 91.1%);
    color:white

}
.sign-in-container2{
    margin-left: inherit !important;
}
.signin-fields{
    background-color: #3D404A;
    border: none;
    border-radius: 5px;
}

.signin-label-text{
    font-size: 1.2em ;
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 5px;
    color: rgb(236, 236, 236);
}

.signIn-hr{
    margin-top: 10px;
    margin-bottom: 20px;
    border: 1px solid gray;
}

.sign-in-btn{
    display: block !important;
    margin-top: 3vh !important;
    width: 50%;
}