*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* @media (max-width:1100px) {
    *{
        display: none;
    }
} */