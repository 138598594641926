.meeting-event {
  background: linear-gradient(195deg, rgb(102, 187, 106), rgb(67, 160, 71));
  color: white;
  padding: 5px;
  margin: 5px 0px;
}

.meeting-event:hover {
  background: linear-gradient(195deg, rgb(67, 120, 70), rgb(67, 160, 71));
}

.lunch-event {
  background: linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232));
  color: white;
  padding: 5px;
  margin: 5px 0px;
}

.lunch-event:hover {
  background: linear-gradient(195deg, rgb(48, 95, 136), rgb(26, 115, 232));
}


.standup-event {
  background: linear-gradient(195deg, rgb(255, 167, 38), rgb(251, 140, 0));
  color: white;
  padding: 5px;
  margin: 5px 0px;
}

.standup-event:hover {
  background: linear-gradient(195deg, rgb(167, 109, 22), rgb(251, 140, 0));
}

.custom-event {
  background: linear-gradient(195deg, rgb(239, 83, 80), rgb(229, 57, 53));
  color: white;
  padding: 5px;
  margin: 5px 0px;
}





/* Style the event list container */
.event-list {
  max-width: 600px;
  margin: 0 auto;
  padding: 10px;
  font-family: Arial, sans-serif;
}


/* Style individual events */
.event {
  background: linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25));
  padding: 20px;
  margin-bottom: 5px;
  border-radius: 0.75rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: start;
}


/* Style event title */
.event-title {
  font-size: 20px;
  color: white;
  margin-bottom: 10px;
  display: flex;
  gap: 7px;
}

/* Style event details (time and location) */
.event-details {
  font-size: 16px;
  color: white;
  margin-bottom: 5px;
  display: flex;
  gap: 7px;
  margin-bottom: 2vh;
}

/* Style event description */
.event-description {
  font-size: 14px;
  color: rgb(32, 32, 32);
  background: linear-gradient(to top, #c4c5c7 0%, #dcdddf 52%, #ebebeb 100%);
  padding: 10px 20px;
  border-radius: 5px;
  font-family: 'poppins';
  font-weight: 500;
}

.events-today-header {
  background: linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25));
  color: white;
  font-size: 25px;
  padding: 10px;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  justify-content: space-between;
  /* Set this to space between elements */
  align-items: center;
  /* Vertically center the content */
}

.task-title {
  display: flex;
  justify-content: space-between;
  /* Set this to space between elements */
  align-items: center;
  /* Vertically center the content */
}

.scroll-tasks {
  max-height: 88.5vh;
  /* Adjust the max-height as needed */
  overflow-y: scroll;
}